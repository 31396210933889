import './App.css';

import { WebsitePage } from "./pages/WebsitePage";
import { ProjectsPage } from "./pages/ProjectsPage";

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Navbar } from './components/Navbar';
import { Footer } from './components/Footer';

function App() {
  return (
    <div className="bg-stone-600">
      
      <Router>
        <Navbar />    
        
        <Routes>
          <Route path="/" end element={<ProjectsPage />} />
          <Route path="/website" element={<WebsitePage />} />
        </Routes>

        <Footer />
      </Router>
      
    </div>
  );
}

export default App;
