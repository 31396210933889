const Title = ({children}) =>{
    return(
        <div className='text-xl uppercase text-center sm:text-2xl font-bold my-2'>
            {children}
        </div>
    )
}

const Subtitle = ({children}) =>{
    return(
        <div className='text-lg capitalize sm:text-2xl font-bold mt-6'>
            {children}
        </div>
    )
}

const Subsubtitle = ({children}) =>{
    return(
        <div className='text-base uppercase sm:text-lg mt-4'>
            {children}
        </div>
    )
}

const Body = ({children}) =>{
    return(
        <div className='text-sm sm:text-lg'>
            {children}
        </div>
    )
}

const Item = ({children}) =>{
    return(
        <div className="text-sm px-4 pt-2">
            {children}
        </div>
    )
}

const Image = (props) =>{
    return(
        <div className='mt-4'>
            <img src={props.photo} className='rounded-2xl object-cover' alt='Foto'/>
            <Description>{props.description}</Description>
        </div>
    )
}

const Description = ({children}) =>{
    return(
        <div className='text-xs text-stone-600 dark:text-stone-100 italic my-1 px-4 text-center'>
        {children}
        </div>
    )
}


export const Common = () => {
  return (
    <div>Common</div>
  )
}

export { Title, Subtitle, Subsubtitle, Body, Item, Image, Description};