import React from 'react'
import SpotifyPhoto from '../assets/website-spotify.png' 
import WorkoutPhoto from '../assets/website-workout.png' 
import { Title, Subtitle, Subsubtitle, Body, Item, Image } from '../components/Common'


export const WebsitePage = () => {
    let spotifyDescription = "The component is created using the embbed feature provided by Spotify."  
    let workoutDescription = "The reason behind this architectural choice is to prevent bad actors from exploiting the daily limit of number of request I'm allowed to send to Fitbit's database."
    return (
    <div className='w-full flex justify-center bg-stone-100 dark:bg-stone-700'>
        <div className='w-full sm:w-2/3 pt-16 sm:pt-24 pb-4 px-8 sm:px-10 dark:bg-stone-600 dark:text-white'>
            
            <div className='text-sm sm:text-2xl text-center mb-4'>⚠️ This Page is still under development ⚠️</div>
            
            <Title>NOTALEPOWER'S WEBSITE</Title>
            <Body>
                Nowadays having a personal website is becoming essential across all job fields. <br/>
                It provides a unique opportunity to: express yourself without constraints, showcase your skills, and make a lasting impression. <br/>
                That’s why I developed this website: to have a space where I can truly represent who I am and what I can do. <br/>
            </Body>

            <Subtitle>1 - Architecture </Subtitle>
            <Body>This section will focus on explaining the reasoning behind the architectural choices made in the development of this website.</Body>
            
            <Subsubtitle>1.1 Front-End </Subsubtitle>
            <Body> React was chosen as the framework for the front-end development due to the following reasons:</Body>
            
            <Item >
                - Excellent Documentation <br/> 
                React provides clear tutorials and explanations of its architecture and components on their website. <br/>
                They also include a web-based sandbox, allowing beginners to apply the concepts they've learned in a hands-on environment, enhancing information retention. 
            </Item>

            <Item >
                - Single Page Application (SPA) <br/>
                From the start, I knew I wanted a SPA website, as it provides fast and smooth user experience by dynamically updating the content on a single page without requiring full page reloads.
            </Item>

            <Item >
                - Popularity <br/>
                Yeah, I know, I'm such a Hypebeast, but being popular also means there is a vast community supporting it, contributing to the development of fantastic pre-made libraries.
            </Item>
            
            <Subsubtitle>1.2 Styling </Subsubtitle>
            <Body>
                Tailwind CSS was chosen as the styling framework for its simplicity and fast implementation. <br/>
                While it may not be the top choice for professional front-end developers ... I am NOT a professional front-end developer. So for my purpose, I think it's more than enough.
            </Body>

            <Subsubtitle>1.3 Back-End </Subsubtitle>
            <Body>
            Cloudflare Pages were used to deliver the static website, while Cloudflare Workers were used to handle API interactions on the back-end to support dynamic content. <br/>
            Although this stack may not be the most common choice among developers, it was selected for its convenience and robust network services provided by Cloudflare.
            </Body>

            <Subtitle> 2 - Content's Layout </Subtitle>
            <Body>
                From the beginning, I knew wanted to split the website into two sections: one focused on my professional experience (like a CV)
                and the other dedicated to personal content (like a blog).
            </Body>

            <Subsubtitle>2.1 Professional's Page Layout </Subsubtitle>
            <Body>
                The first thing a visitor should see upon landing on the page is who am I and my academic background.
                Following that the skills are displayed which specify in which area I'm specialized. <br/>
                The final section is the Project Area, which displays every project that I worked on with a brief description and 
                a link in order to dive deep into the specifics.
            </Body>

            <Subsubtitle>2.2 Personal's Page Layout </Subsubtitle>
            <Body>
                The layout of this page is pretty similar to the previous one, except for its content. <br/>
                This page was meant for people who already knew me, so the hero section, this time, displays
                a list called "Currently on" which I update what am I working on. <br/><br/>

                Following the Hero section there's the Statistics section (i didn't know how to call it) which displays:
                
                <Item>
                    - The most-listened song of the month: Everyone enjoys music, and each month,
                    there's often one song that you can't stop listening to. <br/>
                    This section showcases my monthly favorite songs. <br/>
                    For this feature, the song is manually selected and is NOT based on the statistics in my Spotify account (as I don't have a Premium subscription).
                </Item>
                
                <Image photo={SpotifyPhoto} description={spotifyDescription}/>

                <Item>
                    - Quote section: Have you ever heard a quote that stuck with you, even years later? This section is dedicated to sharing such quotes. <br/>
                    To implement this, I embedded all the quotes directly into the source code (you can view them using the tool inspection lol).
                    I chose this approach because: I don't have a large collection of quotes to store, the content isn't sensitive, and it was quick to implement.
                </Item>

                <Item>
                    - Total calories burned: The health data collected by smartwatches is kinda useless:
                    It doesn't contain valuable information able to infer a person's health or habits (but don't quote me on that).
                    It's also not sensitive, so why not share it? I mean ... what can a malevolent user do with the amount of calories I've burned?
                    Build me a workout plan?! <br/>
                    For its implementation I deployed a custom API server that has 2 mansions:
                    <ul className='px-2'> 
                        <li> 1) Every 4 hours it fetches the data from Fitbit's database using OAuth2 protocol to authenticate and saves the data locally. </li>
                        <li> 2) When invoked, it reads the most recent value and returns it to the browser.</li>
                    </ul>
                    
                    
                </Item>

                <Image photo={WorkoutPhoto} description={workoutDescription}/>

                <Item>
                    - Tweet Generator: Do you want to know how I normally talk? This section is dedicated for that. <br/>
                    For its implementation I trained an AI model on my text conversations (more details on the project here).
                    Then after training I wrapped the model inside a Flask server and deploy it.
                </Item>

                

                <Subtitle>3 - Conclusion </Subtitle>
                <div className='text-sm'>
                    Building a website from scratch was challenging, but overall, it was surprisingly simple. <br/>
                    Here are some key skills and knowledge I gained throughout the process:
                    
                    <Item>- Retrieving dynamic content using APIs.</Item>
                    <Item>- Deploying a website.</Item>
                    <Item>- Creating a responsive and user-friendly design.</Item>
                    <Item>- Implementing support for dark mode.</Item>
                    <Item>- Buying and managing a domain.</Item>
                
                    <br/>
                    The webpage could be further improved, but for now, I'm quite satisfied with the results. <br/>
                    Future improvements will include:
                    <Item>- Migration to Next.js.</Item>
                    <Item>- New sections on the blog page.</Item>
                    <Item>- New pages's projects.</Item>
                </div>  
            </Body>

        </div>
    </div>
  )
}
